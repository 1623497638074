import { computed, ComputedRef } from "vue";
import { NavigationFailure, useRoute, useRouter } from "vue-router";

export default (): {
  displayModal: ComputedRef<boolean>;
  currentModal: ComputedRef<string>;
  childrenRef: ComputedRef<string>;
  openModal: (modalName: string, code?: string) => Promise<void | NavigationFailure | undefined>;
  close: () => Promise<void | NavigationFailure | undefined>;
} => {
  const route = useRoute();
  const router = useRouter();

  /**
   * Add the modal parameter to trigger modal display
   */
  const openModal = (modalName: string, code?: string) => {
    return router.push({
      name: route.name as string,
      query: { ...route.query, modal: modalName, ...(code ? { code } : {}) },
    });
  };

  /**
   * Remove the modal parameter to hide the modal
   */
  const close = () => {
    return router.push({ name: route.name as string, query: { ...route.query, modal: "" } });
  };

  /**
   * Boolean necessary to display or hide the modal
   */
  const displayModal = computed(() => {
    const modalName = route.query.modal as string;
    return modalName != null && modalName !== "";
  });

  /**
   * Get the modal name. This parameter must have the same name as the modal
   */
  const currentModal = computed(() => {
    return route.query.modal as string;
  });

  /**
   * Return the ref name for the current modal
   */
  const childrenRef = computed(() => `contentModal_${(route.query.modal as string) || ""}`);

  return { displayModal, currentModal, childrenRef, openModal, close };
};
