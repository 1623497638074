import { createApp } from "vue";
import { setLocale as setVeeValidateLocale } from "@vee-validate/i18n";
import mmTroPlugin from "@team-uep/vue-mmtro-op";
import iubendaPlugin from "@team-uep/vue-iubenda-op";
import { initSentry, SentryEnvironment } from "@team-uep/vue-sentry";
import vueApiOpPlugin from "@team-uep/vue-api-op";
import smoothscroll from "smoothscroll-polyfill";

import "@/styles/fonts.css";
import "@/styles/tailwind.css";
import "@/styles/index.css";
import "@/styles/n-inputs.scss";
import "@/styles/n-modal.scss";
import "@/styles/left-right-layout.scss";

import App from "@/App.vue";
import router from "@/router/router";
import store from "@/store/index";
import i18n from "@/config/i18n";
import { isDebugMode, isDevEnv, isStagingEnv, isProdEnv } from "@/helpers/context";
import Package from "../package.json";
// eslint-disable-next-line import/extensions
import sentryClirc from "../.sentryclirc";

import "@/vee-validate";
import initSaveQueryToState from "./store/initSaveQueryToState";
import { getCurrentDecliName, getDecliByDomain } from "./helpers/declis";

// eslint-disable-next-line
smoothscroll.polyfill();

const getRecaptchaRenderKey = (): string => {
  const decli = getDecliByDomain();
  if (isProdEnv()) {
    return decli.recaptchaSiteKeyProd;
  }
  return decli.recaptchaSiteKeyStaging;
};

const app = createApp(App);
app
  .use(i18n)
  .use(store)
  .use(router)
  .use(vueApiOpPlugin, {
    modulesOptions: { recaptcha: { renderKey: getRecaptchaRenderKey() } },
  })
  .use(iubendaPlugin)
  .use(mmTroPlugin, {
    debugMode: false,
    prodEnv: isProdEnv(),
  })
  .mount("#app");

// REMOVE CONSOLE LOG IN PRODUCTION
if (isProdEnv() && !isDebugMode()) {
  // eslint-disable-next-line
  console.log = function () {};
}

// Configure Sentry
if (!isDebugMode()) {
  const getSentryEnv = (): SentryEnvironment => {
    if (isDevEnv()) {
      return SentryEnvironment.Development;
    }
    if (isStagingEnv()) {
      return SentryEnvironment.Staging;
    }
    return SentryEnvironment.Production;
  };

  initSentry({
    app,
    vueRouter: router,
    projectName: Package.name,
    projectVersion: Package.version,
    sentryclirc: String(sentryClirc),
    projectEnv: getSentryEnv(),
  });
}

let firstCall = true;
router.beforeEach((to, _, next) => {
  // On first route
  if (to && firstCall) {
    // Save query params to store
    initSaveQueryToState(to.query);
    firstCall = false;
  }

  // Update language
  i18n.global.locale.value = getCurrentDecliName(to);

  // Setting of veeValidate to same language of i18n
  setVeeValidateLocale(i18n.global.locale.value);

  next();
});
