import { OpStatus, getActiveApiOp, ApiOpBackendEventName } from "@team-uep/vue-api-op";
import {
  NavigationFailure,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationRaw,
} from "vue-router";
import { useUserStore } from "@/store/user";
import { useTrackingStore } from "@/store/tracking";
import {
  APISessionCurrentResponse,
  APISessionRelogResponse,
} from "@/types/api-extended.interfaces";
import { updateStoreAfterSessionCurrent } from "@/helpers/store";
import { getRouteByDecliNamePageId } from "@/helpers/routes";
import { isDebugMode } from "@/helpers/context";
import { paths } from "@/types/api.interfaces";
import { getUrlRedirect, redirectToUrl } from "@/helpers/urlRedirect";
import opConfig from "@/config/opConfig";

type DoormanReturn = void | NavigationFailure | undefined | true; // true if no redirection otherwise

type TaskOptions = {
  to: RouteLocationNormalized;
  from: RouteLocationNormalized;
  next: NavigationGuardNext;
};

type Task = (options: TaskOptions) => Promise<DoormanReturn>;

const relog = async (options: TaskOptions): Promise<void | true> => {
  const apiOp = getActiveApiOp();
  const trackingStore = useTrackingStore();
  const userStore = useUserStore();

  if (trackingStore.$state.uid) {
    // Will trigger a `/session/token` before `/session/relog` because no
    // token have been generated at this time of execution.
    const { data: relogData }: { data: APISessionRelogResponse } = await apiOp.relog(
      trackingStore.$state.uid
    );

    trackingStore.$patch({
      idfrom: relogData.data[0].fromId,
      idvisit: relogData.data[0].visitId,
      pageid: relogData.data[0].iPageId,
      mmtro: {
        tagid: relogData.data[0].oTagInfo.tagid,
      },
      uid: relogData.data[0].uid,
    });

    const { data: sessionData }: { data: APISessionCurrentResponse } =
      await apiOp.getSessionCurrent();

    updateStoreAfterSessionCurrent(sessionData, userStore, trackingStore);

    // Redirect where the user has left using the `pageid` returned from
    // relog endpoint which is stored at `trackingStore.$state.pageid`.
    if (options.to.meta.pageId !== trackingStore.$state.pageid) {
      const route = getRouteByDecliNamePageId(
        String(options.to.meta.decliName),
        Number(trackingStore.$state.pageid)
      );

      if (!route) {
        throw new Error(`Page ID ${String(trackingStore.$state.pageid)} not found for relog`);
      }

      return options.next(route);
    }
  }
  return true;
};
class Doorman {
  tasks: Task[] = [];

  /**
   * Check if the user is authenticated
   */
  isAuth(redirect: RouteLocationRaw): Doorman {
    return this.addTask(async (options: TaskOptions): Promise<DoormanReturn> => {
      const trackingStore = useTrackingStore();

      // If user uid not defined redirect the page
      if (!trackingStore.$state.uid) {
        return Promise.resolve(options.next(redirect));
      }

      return Promise.resolve(true);
    });
  }

  /**
   * Check the OP status and redirect if it not live
   */
  isLive(redirect: RouteLocationRaw): Doorman {
    return this.addTask(async (options: TaskOptions): Promise<DoormanReturn> => {
      const apiOp = getActiveApiOp();

      if (apiOp.opStatus.value === null) {
        await apiOp.getSessionToken(Doorman.getSessionTokenPayload());
      }

      if (apiOp.opStatus.value !== OpStatus.Live) {
        return options.next(redirect);
      }

      return true;
    });
  }

  subscribe(): Doorman {
    return this.addTask(async (options: TaskOptions): Promise<DoormanReturn> => {
      const userStore = useUserStore();
      const trackingStore = useTrackingStore();
      const decliName =
        opConfig.declis.find((decli) => decli.name === options.to?.meta?.decliName)?.name || "";
      const redirectUrl = getUrlRedirect(decliName);

      if (
        (userStore.$state.email || userStore.$state.phonenumber) &&
        userStore.$state.responses.clientId
      ) {
        const apiOp = getActiveApiOp();

        try {
          let url = `inscription/client?id_client=${encodeURIComponent(
            userStore.$state.responses.clientId
          )}`;

          if (userStore.$state.email) {
            url += `&email=${encodeURIComponent(userStore.$state.email)}`;
          }

          if (userStore.$state.phonenumber) {
            url += `&mobile=${encodeURIComponent(userStore.$state.phonenumber)}`;
          }

          const { data: APIData } = await apiOp.get<
            paths["/inscription/client"]["get"]["responses"]["200"]["content"]["application/json"]
          >(url);
          const user = APIData.data[0];
          userStore.$patch({
            firstname: user.prenom || null,
            lastname: user.nom || null,
            email: user.email || null,
            phonenumber: user.telmobile || null,
            responses: {
              clientId: user.id_Client || null,
            },
          });

          trackingStore.$patch({
            uid: user.uid,
          });

          // If uid Relog
          if (user.uid) {
            return relog(options);
          }

          // If no uid suscribe register
          const formData: paths["/inscription/register"]["post"]["requestBody"]["content"]["application/json"] =
            {
              sEmail: user.email,
              sMobile: user.telmobile,
              sFirstName: user.prenom,
              sLastName: user.nom,
              oResponses: {
                sQuestion_101: user.id_Client,
              },
              oOptins: {
                iOptin_2: 1,
              },
            };

          // Force pageview to avoid API 403
          await apiOp.pageView({ iPageId: options.to.meta.pageId });

          await apiOp.register(formData);
          const response = await apiOp.getSessionCurrent();

          updateStoreAfterSessionCurrent(
            response.data as APISessionCurrentResponse,
            userStore,
            trackingStore
          );

          return true;
        } catch (error) {
          await redirectToUrl(redirectUrl);
          return true;
        }
      }

      await redirectToUrl(redirectUrl);
      return true;
    });
  }

  /**
   * Check the OP status and redirect if it not end
   */
  isEnded(redirect: RouteLocationRaw): Doorman {
    return this.addTask(async (options: TaskOptions): Promise<DoormanReturn> => {
      const apiOp = getActiveApiOp();

      if (apiOp.opStatus.value === null) {
        await apiOp.getSessionToken(Doorman.getSessionTokenPayload());
      }

      if (apiOp.opStatus.value !== OpStatus.Ended) {
        return options.next(redirect);
      }

      return true;
    });
  }

  /**
   * Check the OP status and redirect if it not unstarted
   */
  isUnstarted(redirect: RouteLocationRaw): Doorman {
    return this.addTask(async (options: TaskOptions): Promise<DoormanReturn> => {
      const apiOp = getActiveApiOp();

      if (apiOp.opStatus.value === null) {
        await apiOp.getSessionToken(Doorman.getSessionTokenPayload());
      }

      if (apiOp.opStatus.value !== OpStatus.Unstarted) {
        return options.next(redirect);
      }

      return true;
    });
  }

  /**
   * Relog using UID from tracking-store if needed. UID can be set from
   * the session-storage or a URL query parameter.
   *
   * We assume a user need to relog if there is no `email` property in the
   * user-store.
   *
   * @returns the Doorman instance used.
   */
  checkRelog(): Doorman {
    return this.addTask(async (options: TaskOptions): Promise<DoormanReturn> => {
      const trackingStore = useTrackingStore();
      const userStore = useUserStore();

      // We assume a user try to relog if the UID (inserted into store from URL
      // query parameter) is defined but the email is empty.
      if (trackingStore.$state.uid && userStore.$state.email === null) {
        return relog(options);
      }

      return true;
    });
  }

  /**
   * Get current session API data
   */
  checkSession(): Doorman {
    return this.addTask(async (options: TaskOptions): Promise<DoormanReturn> => {
      const trackingStore = useTrackingStore();
      const userStore = useUserStore();
      const apiOp = getActiveApiOp();
      const { data: dataSession }: { data: APISessionCurrentResponse } =
        await apiOp.getSessionCurrent();

      updateStoreAfterSessionCurrent(dataSession, userStore, trackingStore);

      return options.next();
    });
  }

  /**
   * Send pageview to API and redirect if route is unauthorized
   */
  checkPageView(redirect: RouteLocationRaw | boolean = false): Doorman {
    return this.addTask(async (options: TaskOptions): Promise<DoormanReturn> => {
      const apiOp = getActiveApiOp();

      const cbApiPageView = async (r: { response: Response; data: unknown }) => {
        const json = (await r.response.json()) as { data: number[] };

        const route = getRouteByDecliNamePageId(
          String(options.to.meta.decliName),
          Number(json.data[0])
        );

        if (!route) {
          throw new Error(`Page ID ${String(json.data[0])} not found for relog`);
        }
        options.next(route);

        return r;
      };

      apiOp.on(ApiOpBackendEventName.UNAUTHORIZED, cbApiPageView);

      try {
        await apiOp.pageView({ iPageId: options.to.meta.pageId });
      } catch (error) {
        if (redirect) {
          return options.next(redirect as RouteLocationRaw);
        }
      }

      apiOp.off(ApiOpBackendEventName.UNAUTHORIZED, cbApiPageView);

      return true;
    });
  }

  /**
   * Start the process
   */
  start(): (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => Promise<void> {
    return async (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ): Promise<void> => {
      const options = {
        to,
        from,
        next,
      };
      if (isDebugMode()) {
        next();
      } else {
        await this.runTasks([...this.tasks], options);
      }
    };
  }

  /**
   * Start all tasks
   */
  private async runTasks(tasks: Task[], options: TaskOptions): Promise<DoormanReturn> {
    const task = tasks.shift();
    if (task) {
      // Stop tests if a route is already called
      const continueTasks = await task(options);
      if (continueTasks === true) {
        return this.runTasks(tasks, options);
      }
      return continueTasks;
    }

    return options.next();
  }

  /**
   * Insert a new task
   */
  private addTask(task: Task): Doorman {
    this.tasks.push(task);
    return this;
  }

  /**
   * Return the payload to send for the `/session/token` API endpoint.
   */
  private static getSessionTokenPayload() {
    const trackingStore = useTrackingStore();
    const urlParameters = new URLSearchParams(window.location.search);

    return {
      fromId: trackingStore.$state.idfrom || 0,
      oUrlParams: Object.fromEntries(urlParameters.entries()),
      parrainId: trackingStore.$state.idup ? parseInt(trackingStore.$state.idup, 10) : 0,
      sReferrer: "",
      sRes: `${window.screen.width}x${window.screen.height}`,
    };
  }
}

/**
 * Initialise Doorman instance
 */
const doorman = (): Doorman => {
  return new Doorman();
};

export default doorman;
