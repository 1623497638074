import { inject, watch } from "vue";
import { useRoute } from "vue-router";
import { MmTro, MmTroUserData } from "@team-uep/vue-mmtro-op";
import { Iubenda } from "@team-uep/vue-iubenda-op";

import { useUserStore } from "@/store/user";
import { useTrackingStore } from "@/store/tracking";

export default (): void => {
  const route = useRoute();
  const userStore = useUserStore();
  const trackingStore = useTrackingStore();
  const mmtro = inject<MmTro>("mmTro");
  const iubenda = inject<Iubenda>("iubenda");

  watch(
    // Call it if route change or mmtro tag ID change
    () => `${String(route.name)}-${trackingStore.$state.mmtro.tagid || ""}`,
    () => {
      if (mmtro && trackingStore.$state.mmtro.tagid) {
        console.warn(
          "Mmtro is ALWAYS sending tracking, whatever the state of Iubenda banner is set to."
        );

        const mmtroConfig: MmTroUserData = {
          config: {},
          global: {
            oTagInfoTagid: trackingStore.$state.mmtro.tagid,
            idFrom: trackingStore.idfrom ? trackingStore.idfrom.toString() : "0",
            idVisit: trackingStore.idvisit || undefined,
          },
          user: {
            idFrom: trackingStore.idfrom ? trackingStore.idfrom.toString() : "0",
            oOptins: {},
          },
        };

        if (userStore.id) {
          mmtroConfig.user.idu = userStore.id;
        }

        if (userStore.email) {
          mmtroConfig.user.email = userStore.email;
        }

        if (userStore.optins) {
          if (mmtroConfig.user.oOptins && userStore.optins.emailBrand) {
            mmtroConfig.user.oOptins.iOptin2 = userStore.optins.emailBrand;
          }
        }

        mmtro.setUserData(mmtroConfig);
        if (route.meta.pageId) {
          mmtro.addPageId(Number(route.meta.pageId));
        }
        if (route.meta.register) {
          mmtro.addFormPage();
        } else if (mmtro.getQueue().rtgpg !== "completedform") {
          mmtro.addGenericPage();
        }

        if (iubenda && iubenda.mmtroConsent) {
          mmtro.sendRtg();
        }
      }
    }
  );
};
