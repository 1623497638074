import { APISessionCurrentResponse } from "@/types/api-extended.interfaces";
import { UserStoreType, TrackingStoreType } from "@/types/store.interfaces";

/**
 * Update user and tracking stores after a `/session/current` endpoint.
 *
 * @param data API response data from `/session/current` endpoint.
 * @param userStore Provided user-store.
 * @param trackingStore Provided tracking store.
 */
// eslint-disable-next-line import/prefer-default-export
export const updateStoreAfterSessionCurrent = (
  data: APISessionCurrentResponse,
  userStore: UserStoreType,
  trackingStore: TrackingStoreType
): void => {
  userStore.$patch({
    ...(data.data[0].idU && Number(data.data[0].idU) ? { id: Number(data.data[0].idU) } : {}),
    ...(data.data[0].Email ? { email: data.data[0].Email } : {}),
    ...(data.data[0].Prenom ? { firstname: data.data[0].Prenom } : {}),
    ...(data.data[0].Nom ? { lastname: data.data[0].Nom } : {}),
    ...(data.data[0].oOptins.iOptin_2
      ? { optins: { emailBrand: data.data[0].oOptins.iOptin_2 } }
      : {}),
    ...(data.data[0].oResponses.sQuestion_101
      ? { responses: { clientId: String(data.data[0].oResponses.sQuestion_101) } }
      : {}),
  });

  trackingStore.$patch({
    ...(data.data[0].id_From !== undefined ? { idfrom: data.data[0].id_From } : {}),
    ...(data.data[0].uid !== undefined ? { uid: data.data[0].uid } : {}),
    ...(data.data[0].idu_email !== undefined ? { iduEmail: data.data[0].idu_email } : {}),
  });
};
