import { configure, defineRule } from "vee-validate";
import { required, email, min, max } from "@vee-validate/rules";
import { localize, setLocale } from "@vee-validate/i18n";
import {
  ageMaxRule,
  ageMinRule,
  dateRule,
  latinStringRule,
  phoneNumberRule,
} from "@team-uep/n-inputs";
import { useRoute } from "vue-router";
import { getCurrentDecli } from "@/helpers/declis";

import messages from "../translations.base.csv";
import opConfig from "./config/opConfig";

const route = useRoute();
const decli = getCurrentDecli(route);

type ValidatorFunction = (value: unknown, params?: unknown) => boolean;

/**
 * Custom vee-validate rule to handle email already registered error message.
 * This rule should as following this small example:
 *
 * ```typescript
 *  // Update your ref after a post-register, which should contain a specific
 *  // error message when the entered user email is already user.
 *  const isEmailAlreadyRegistered = ref(false);
 *  const emailRule = computed(() => `email|emailAlreadyRegistered:${isEmailAlreadyRegistered.value}`);
 * ```
 *
 * @param value vee-validate input value (user entered email).
 * @param params value after the rule name `:`. Should contain a boolean in the
 * form of a string.
 * @returns a boolean, which indicate if the rule is passed or not.
 */
const emailAlreadyRegisteredRule = (value: string, params: [string]): boolean => {
  return params[0] === "false";
};
const radioRequiredRule = (value: number): boolean => value === 2 || value === 1;

defineRule("required", required);
defineRule("quizRequired", required);
defineRule("radioRequired", radioRequiredRule);
defineRule("email", email);
defineRule("emailAlreadyRegistered", emailAlreadyRegisteredRule);
defineRule("min", min);
defineRule("max", max);
defineRule("isPhoneNumberValid", phoneNumberRule as ValidatorFunction);
defineRule("ageMax", ageMaxRule as ValidatorFunction);
defineRule("ageMin", ageMinRule as ValidatorFunction);
defineRule("date", dateRule as ValidatorFunction);
defineRule("latinString", latinStringRule as ValidatorFunction);

// Disbling the following ESLint rules until we can get a properly typed translation file.
/*
  eslint
    @typescript-eslint/no-unsafe-assignment: "off",
    @typescript-eslint/no-unsafe-call: "off",
    @typescript-eslint/no-unsafe-member-access: "off"
*/
const errorTraductions = opConfig.declis.reduce(
  (object, currDecli) => ({
    ...object,
    [currDecli.name]: {
      messages: {
        required: messages[currDecli.name]?.errors?.required,
        // radioRequired: messages[currDecli.name].errors.required,
        email: messages[currDecli.name]?.errors?.email?.invalid,
        // emailAlreadyRegistered: messages[currDecli.name].errors.email.alreadyRegistered,
        // ageMax: messages[currDecli.name].errors.birthday.tooOld,
        // ageMin: messages[currDecli.name].errors.birthday.tooYoung,
        // date: messages[currDecli.name].errors.birthday.invalid,
        // latinString: messages[currDecli.name].errors.invalid,
        // isPhoneNumberValid: messages[currDecli.name].errors.invalid,
        // min: messages[currDecli.name].errors.min,
        // max: messages[currDecli.name].errors.max,
        // quizRequired: messages[currDecli.name].errors.answer.required,
      },

      names: {
        // civility: messages[currDecli.name].register.form.gender.toLowerCase(),
        // firstname: messages[currDecli.name].register.form.firstname.toLowerCase(),
        // lastname: messages[currDecli.name].register.form.lastname.toLowerCase(),
        email: messages[currDecli.name]?.register?.form?.email?.toLowerCase(),
        // birthdate: messages[currDecli.name].register.form.birthday.toLowerCase(),
        // phone: messages[currDecli.name].register.form.mobile.toLowerCase(),
      },
    },
  }),
  {}
);

configure({
  generateMessage: localize(errorTraductions),
});

setLocale(decli.name);
