import { RouteRecordRaw } from "vue-router";
import declisData, { decliConfig } from "@/declis/index";
import opConfig from "@/config/opConfig";
import { getDecliByDomain } from "@/helpers/declis";
import mainRoutes from "@/router/globalRoutes";
import globalSharedData from "@/hooks/globalSharedData";

const currentDecliName: string = getDecliByDomain().name;

// Check if the current decli name exist
if (!declisData.find((decliData) => decliData.decliName === currentDecliName)) {
  throw new Error(`The decli ${currentDecliName} is not referenced on src/declis/index.ts`);
}

/**
 * Get the priority of the route
 *
 * @param {RouteRecordRaw} route
 * @returns {number} Priority weight
 */
function getPriorityWeight(route: RouteRecordRaw) {
  if (route?.meta?.decliName === currentDecliName) {
    return 3;
  }
  if (route?.meta?.decliName === decliConfig.mainDecli) {
    return 2;
  }
  return 1;
}

/**
 * Inject decliName in meta to a route
 *
 * @param {RouteRecordRaw} route
 * @param {string} decliName
 * @returns RouteRecordRaw
 */
function injectDecli(route: RouteRecordRaw, decliName: string): RouteRecordRaw {
  const meta = route.meta || {};
  meta.decliName = decliName;

  // Recursive inject decli name to children
  if (route.children) {
    for (let i = 0; i < route.children.length; i += 1) {
      injectDecli(route.children[i], decliName);
    }
  }

  return {
    ...route,
    meta,
  };
}

/**
 * Get all routes with decliName inside
 * @returns {Array<RouteRecordRaw>}
 */
function getAllRoutes(): Array<RouteRecordRaw> {
  const decliRoutes: RouteRecordRaw[] = opConfig.declis
    .map((decli) => {
      const decliName = decli.name;
      const decliData = declisData.find((dd) => dd.decliName === decliName);

      if (!decliData) {
        throw new Error(`The decli ${decliName} is not referenced on src/declis/index.ts`);
      }

      // Inject decliName to each route
      return decliData.routes.map((route) => injectDecli(route, decliName));
    })
    .flat(1);

  const allroutes = [...decliRoutes, ...mainRoutes].filter((route) => {
    // Remove other routes by decli if !multiDecliOnSameURL
    if (
      !decliConfig.multiDecliOnSameURL &&
      route?.meta?.decliName &&
      route?.meta?.decliName !== currentDecliName
    ) {
      return false;
    }
    return true;
  });

  // Sort by priority
  allroutes.sort((routeA, routeB) => getPriorityWeight(routeB) - getPriorityWeight(routeA));

  return allroutes;
}

const routes = getAllRoutes();

globalSharedData.routes = routes;

export default routes;
