import { createI18n } from "vue-i18n";

import { getDecliByDomain } from "@/helpers/declis";
import messages from "../../translations.base.csv";

const localeName = getDecliByDomain().name;

/**
 * Escape @ to {'@'} to prevent i18n
 * https://vue-i18n.intlify.dev/guide/essentials/syntax.html#literal-interpolation
 */
function escapeArobase(str: string): string {
  return str.split("@").join("{'@'}");
}

type Message = { [key: string]: Message } | string;

/**
 * Recursive call to escape arobase
 */
function sanitizeMessage(message: Message): Message {
  if (typeof message === "string") {
    return escapeArobase(message);
  }

  if (typeof message === "number") {
    return String(message);
  }

  Object.keys(message as { [key: string]: Message }).forEach((key) => {
    // eslint-disable-next-line no-param-reassign
    message[key] = sanitizeMessage(message[key]);
  });

  return message;
}

const i18n = createI18n({
  locale: localeName || "en",
  fallbackLocale: "en",

  // By default vue-i18n uses "Legacy mode" which supports Options API and Composition API.
  // Legacy mode will enable strict vue-i18n syntax, and since we only use Composition API,
  // we can safely opt-out of this mode as recommended in the vue-i18n guide:
  // https://vue-i18n.intlify.dev/guide/advanced/composition.html#basic-usage
  legacy: false,

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment
  messages: sanitizeMessage(messages as Message) as any,
});

export default i18n;
