import { defineStore } from "pinia";
import { TrackingState } from "@/types/store.interfaces";

// eslint-disable-next-line import/prefer-default-export
export const useTrackingStore = defineStore({
  id: "tracking",

  state: () =>
    ({
      idfrom: null,
      idup: null, // sponsorship
      uid: null,
      iduEmail: null,
      idvisit: null,
      pageid: null,
      mmtro: {
        tagid: null,
      },
    } as TrackingState),

  getters: {},

  actions: {},
});
