import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full bg-[#f4f4f4]" }
const _hoisted_2 = { class: "pipes flex flex-row flex-wrap items-center justify-center py-4 text-[14px]" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]

import { inject } from "vue";
import { Iubenda } from "@team-uep/vue-iubenda-op";
import useModal from "@/hooks/useModal";


export default /*@__PURE__*/_defineComponent({
  __name: 'SiteFooter',
  setup(__props) {

const iubenda = inject("iubenda") as Iubenda;
const { openModal } = useModal();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        "data-cy": "terms-open-cta",
        class: "cursor-pointer",
        href: _ctx.$t('footer.termsLink'),
        rel: "noopener noreferrer",
        target: "_blank"
      }, _toDisplayString(_ctx.$t("footer.terms")), 9, _hoisted_3),
      _createElementVNode("button", {
        class: "iubenda-cs-preferences-link cursor-pointer",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(iubenda).openPreferencesModal()))
      }, _toDisplayString(_ctx.$t("footer.cookiesPreferences")), 1),
      _createElementVNode("button", {
        class: "cursor-pointer",
        "data-cy": "cookies-open-cta",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(openModal)('IubendaPrivacyPolicy')))
      }, _toDisplayString(_ctx.$t("footer.cookies")), 1),
      _createElementVNode("a", {
        "data-cy": "contact-cta",
        href: _ctx.$t('footer.contact.link'),
        target: "_blank",
        class: "cursor-pointer",
        rel: "noopener noreferrer"
      }, _toDisplayString(_ctx.$t("footer.contact.button")), 9, _hoisted_4)
    ])
  ]))
}
}

})