<template>
  <footer class="w-full bg-[#f4f4f4]">
    <div class="pipes flex flex-row flex-wrap items-center justify-center py-4 text-[14px]">
      <!-- Terms -->
      <a
        data-cy="terms-open-cta"
        class="cursor-pointer"
        :href="$t('footer.termsLink')"
        rel="noopener noreferrer"
        target="_blank"
      >
        {{ $t("footer.terms") }}
      </a>

      <!-- Iubenda cookies preferences -->
      <button
        class="iubenda-cs-preferences-link cursor-pointer"
        @click="iubenda.openPreferencesModal()"
      >
        {{ $t("footer.cookiesPreferences") }}
      </button>

      <!-- Iubenda cookies -->
      <button
        class="cursor-pointer"
        data-cy="cookies-open-cta"
        @click="openModal('IubendaPrivacyPolicy')"
      >
        {{ $t("footer.cookies") }}
      </button>

      <!-- Contact us mailto -->
      <a
        data-cy="contact-cta"
        :href="$t('footer.contact.link')"
        target="_blank"
        class="cursor-pointer"
        rel="noopener noreferrer"
      >
        {{ $t("footer.contact.button") }}
      </a>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { inject } from "vue";
import { Iubenda } from "@team-uep/vue-iubenda-op";
import useModal from "@/hooks/useModal";

const iubenda = inject("iubenda") as Iubenda;
const { openModal } = useModal();
</script>

<style lang="scss">
.pipes > * {
  margin-right: 1.2rem;
  &::after {
    position: absolute;
    content: "|";
    margin-left: 0.5rem;
    pointer-events: none;
  }
  &:last-child {
    margin-right: 0;
    &::after {
      display: none;
    }
  }
}
</style>
