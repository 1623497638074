import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute top-0 left-0 flex h-screen w-full items-center justify-center bg-white"
}
const _hoisted_2 = { class: "n-icon-loading" }

import { toRefs } from "vue";
import { NIconLoading } from "@team-uep/n-icon";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingScreen',
  props: {
    display: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const { display } = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    name: "fade",
    duration: { enter: 500, leave: 1500 }
  }, {
    default: _withCtx(() => [
      (_unref(display))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(NIconLoading), { color: "#e41010" })
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})