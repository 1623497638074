import { RouteRecordRaw } from "vue-router";
// import doorman from "@/navigation-guard/doorman";

const routes: Array<RouteRecordRaw> = [
  {
    name: "ErrorPage",
    path: "/error",
    // component: ErrorPage,
    component: () => import(/* webpackChunkName: "home" */ "@/views/global/Error.vue"),
  },
  // {
  //   name: "OptinisationPageEn",
  //   path: "/optinization",
  //   // component: OptinisationPage,
  //   // beforeEnter: AppInit,
  //   component: () => import(/* webpackChunkName: "home" */ "@/views/global/Optinisation.vue"),
  //   meta: {
  //     decliName: "en",
  //   },
  //   beforeEnter: doorman().checkRelog().isAuth({ name: "registerEn" }).start(),
  // },
  // {
  //   name: "OptinisationPageFr",
  //   path: "/optinisation",
  //   // component: OptinisationPage,
  //   // beforeEnter: AppInit,
  //   component: () => import(/* webpackChunkName: "home" */ "@/views/global/Optinisation.vue"),
  //   meta: {
  //     decliName: "fr",
  //   },
  //   beforeEnter: doorman().checkRelog().isAuth({ name: "registerFr" }).start(),
  // },
  {
    name: "Error",
    path: "/:catchAll(.*)",
    component: () => import(/* webpackChunkName: "error" */ "@/views/global/Error.vue"),
  },
];

export default routes;
