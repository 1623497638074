import messages from "../../translations.base.csv";

export const getUrlRedirect = (decliName: string) => {
  return decliName
    ? (messages as { [key: string]: { redirect: { url: string } } })[decliName]?.redirect?.url
    : "";
};

export const redirectToUrl = async (url: string) => {
  // Hide page during redirect
  document.body.style.opacity = "0";

  // Redirect
  window.location.href = url;

  // wait 30sec before throw error if redirect not working
  await new Promise((resolve) => {
    setTimeout(resolve, 30000);
  });

  // document.body.style.opacity = "";
  // options.next(redirect);
  throw new Error(`Redirect to ${url} not working`);
};
