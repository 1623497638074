import routesEn from "@/declis/en/routes";
import routesFr from "@/declis/fr/routes";
import routesBeNl from "@/declis/be-nl/routes";
import routesCaEn from "@/declis/ca-en/routes";
import routesCaFr from "@/declis/ca-fr/routes";
import routesEs from "@/declis/es/routes";
import routesIs from "@/declis/is/routes";
import routesSzDe from "@/declis/sz-de/routes";
import routesSzFr from "@/declis/sz-fr/routes";

export const decliConfig = {
  mainDecli: "is",
  multiDecliOnSameURL: false,
};

export default [
  {
    decliName: "fr",
    routes: routesFr,
  },
  {
    decliName: "en",
    routes: routesEn,
  },
  {
    decliName: "be-nl",
    routes: routesBeNl,
  },
  {
    decliName: "ca-en",
    routes: routesCaEn,
  },
  {
    decliName: "ca-fr",
    routes: routesCaFr,
  },
  {
    decliName: "es",
    routes: routesEs,
  },
  {
    decliName: "is",
    routes: routesIs,
  },
  {
    decliName: "sz-de",
    routes: routesSzDe,
  },
  {
    decliName: "sz-fr",
    routes: routesSzFr,
  },
];
