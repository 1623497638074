import { RouteRecordRaw } from "vue-router";

import { RouteMeta } from "@/types/routes.interfaces";
import doorman from "@/navigation-guard/doorman";

const routes: Array<RouteRecordRaw> = [
  {
    name: "registerEn",
    path: "/",
    alias: "/en",
    component: () => import(/* webpackChunkName: "register" */ "@/views/Register.vue"),
    meta: {
      pageId: 1,
      register: true,
    } as RouteMeta,
    beforeEnter: doorman()
      .checkRelog()
      .isLive({ name: "endEn" })
      .subscribe()
      .checkPageView()
      .start(),
  },
  {
    name: "endEn",
    path: "/end",
    component: () => import(/* webpackChunkName: "end" */ "@/views/End.vue"),
    meta: {
      pageId: 8,
    } as RouteMeta,
    beforeEnter: doorman().checkPageView().start(),
  },
];
export default routes;
