<template>
  <transition name="fade" :duration="{ enter: 500, leave: 1500 }">
    <div
      v-if="display"
      class="absolute top-0 left-0 flex h-screen w-full items-center justify-center bg-white"
    >
      <div class="n-icon-loading">
        <n-icon-loading color="#e41010" />
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { toRefs } from "vue";
import { NIconLoading } from "@team-uep/n-icon";

const props = defineProps<{
  display: boolean;
}>();

const { display } = toRefs(props);
</script>

<style lang="scss" scoped>
.n-icon-loading {
  animation: slidein 1s cubic-bezier(0.26, 0, 0.23, 1);
}
@keyframes slidein {
  from {
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}
.fade-leave-active {
  transition: opacity 0.5s 1s ease;
}
.fade-leave-to {
  opacity: 0;
}
</style>
